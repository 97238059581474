.App {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('/public/img/background.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.HeaderBar {
  border-radius: 12px;
  width: 100%;
  margin: auto;
  height: 64px;
  display: flex;
  margin-top: 12px;
  align-items: center;
}

.BarLeftContainer {
  width: fit-content;
  padding-left: 4px;
}

.BarLeft {

}

.HeaderBarLogo {
  height: 48px;
  cursor: pointer;

}

.BarRightContainer {
  flex-grow: 1;
  padding-right: 8px;
}

.BarRight {
  display: inline-flex;
  align-items: center;
  gap: 48px;
  width: 100%;
  justify-content: right;
}

.HeaderBarButton {
  background: none;
  box-shadow: none;
  border: none;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}


.FooterSectionContainer {
  position: absolute;
  bottom: 48px;
  width: 100%;
  text-align: center;
  transition: all 0.25s ease-in-out;
}

.FooterSection {
  display: flex;
  flex-direction: column;
  gap: 72px;
  width: 95%;
}

.FooterTitle {
  font-size: 40px;
  color: #fff;

}



.FooterLogo {
  width: 60%;
  margin: auto;
}

@media (min-width: 768px) {
  .FooterLogo {
    width: 400px;
  }
}


.PurePlay {
  background: -webkit-linear-gradient(#eee, #5E84B2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AI {
  background: -webkit-linear-gradient(#eee, #83A0C6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Infrastructure {
  background: -webkit-linear-gradient(#eee, #3367AA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ContactFormContainer {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  top: 0;
  transition: all 0.25s ease-in-out;
  z-index: 2;

}

.ContactForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px;
  border-radius: 4px;
  width: 90%;
  margin: auto;
}

.FormTitle {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
}

.FormInput {
  width: calc(100% - 24px);
  height: 40px;
  background: #fff;
  border: none;
  border-radius: 4px;
  text-indent: 12px;
  font-size: 15px;

}

.TextAreaInput {
  width: calc(100% - 24px);
  height: 80px;
  background: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  text-indent: 4px;

}

.InputLabel {
  font-size: 13px;
  font-weight: 500;
  padding: 4px 0px 8px 0px;
  color: #fff;
  text-align: left;
}

.FormSubmitButtonContainer {
  text-align: center;
}

.FormSubmitButton {
  width: fit-content;
  padding: 8px 16px;
  background: #3367AA;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.FormSubmitButton:disabled {
  background: grey;
  color: lightgrey;
}

.FooterContainer {
  position: fixed;
  bottom: 12px;
  z-index: 1;
  text-align: center;
  width: 100%;

}

.FooterContent {
  color: #fff;
  font-size: 13px;
}

.HeaderBarMenuItem {
  display: none;
}

.MobileBarMenuItem {
  display: block;;
  text-align: right;
}

@media (min-width: 768px) {
  .HeaderBar {
    background: rgba(255, 255, 255, 0.2);
    width: 90%;
    margin-top: 24px;
  }
  .HeaderBarLogo {
    height: 58px;
  }
  .FooterSection {
    width: 100%;
  }
  .ContactFormContainer {
    top: 160px;
  }
  .ContactForm {
    width: fit-content;
    margin: 40px auto;
    background: rgba(0, 0, 0, 0.25);
  }
  .FormInput {
    width: 400px;
  }

  .TextAreaInput {
    width: 400px;
  }
  .FooterContainer {
    right: 12px;
    text-align: unset;
    width: unset;
  }

  .HeaderBarMenuItem {
    display: block;
  }
  .FooterTitle {
    font-size: 64px;
  }
}